<template>
    <div class="content-box">
        <el-card class="my-20">
            <div class="d-flex-center">
                <div class="d-inline-flex-center is-link" @click="$nav.back()">
                    <i class="icon icon-back my-8 mr-8" />
                    <span class="color-666">{{ $t('base.back') }}</span>
                </div>
                <div class="font-16 ml-20 pl-20 mr-auto font-bold color-666 border-left">{{ $t('account.title') }}</div>
                <el-button type="primary" icon="el-icon-setting" plain @click="$nav.push('/user/settings')">{{ $t('account.settings') }}</el-button>
            </div>
        </el-card>
        <el-row :gutter="20" class="mb-50 color-b">
            <el-col :span="5">
                <div
                    v-for="(item, index) in navMenu" :key="index"
                    class="bg-white box-shadow border-r p-20 is-link menu-item d-flex-center"
                    :class="{ active: $route.path == item.route || $route.name == item.name}"
                    @click="onNav(item.route)"
                >
                    <i class="mr-10" :class="item.icon" />
                    <span class="mr-auto" v-text="item.name" />
                    <i class="el-icon-arrow-right" />
                </div>
            </el-col>
            <el-col :span="19">
                <router-view />
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // active: 0
            navMenu: [
                {
                    icon: 'el-icon-user',
                    name: this.$t('account.profile'),
                    route: '/user/account/profile'
                },
                {
                    icon: 'el-icon-money',
                    name: this.$t('account.my_assets'),
                    route: '/user/account/assets'
                },

                //新增冷钱包地址管理
                {
                    icon: 'el-icon-notebook-2',
                    name: this.$t('account.addressTitle'),
                    route: '/user/account/address'
                },

                //新增银行卡管理
                {
                    icon: 'el-icon-notebook-2',
                    name: this.$t('account_fiat1'),
                    route: '/user/account/bank'
                },

                // 隱藏法幣 2023.2.5
                {
                    icon: 'el-icon-notebook-2',
                    name: this.$t('account_fiat2'),
                    route: '/user/account/fiat'
                },

                //把法幣的USDT快捷賣出 換去個人中心裡面
                //隱藏法幣相關 2023.2.5
                // {
                //     icon: 'el-icon-coin',
                //     name: this.$t('fiat_currency.onTab2'),
                //     route: '/user/account/quick_sale'
                // },

                // {
                //     icon: 'el-icon-notebook-2',
                //     name: this.$t('account_fiat3'),
                //     route: '/user/account/recycle'
                // },

                //新增新幣申購訂單
                {
                    icon: 'el-icon-notebook-2',
                    name: this.$t('account_fiat4'),
                    route: '/user/account/issue_order'
                },

                {
                    icon: 'el-icon-notebook-2',
                    name: this.$t('account_details.router'),
                    route: '/user/account/details'
                },

                {
                    icon: 'el-icon-coin',
                    name: this.$t('recharge.title'),
                    route: '/user/account/recharge'
                },
                {
                    icon: 'el-icon-coin',
                    name: this.$t('withdraw.title'),
                    route: '/user/account/withdraw/USDT'
                },

                // {
                //     icon: 'el-icon-coin',
                //     name: this.$t('exchange.title'),
                //     route: '/user/account/exchange'
                // },

                //暫時隱藏微合約
                // {
                //     icon: 'el-icon-tickets',
                //     name: this.$t('account.micro_contract'),
                //     route: '/user/account/micro_contract'
                // },


                {
                    icon: 'el-icon-tickets',
                    name: this.$t('account.transactions'),
                    route: '/user/account/transactions'
                },
                {
                    icon: 'el-icon-tickets',
                    name: this.$t('account.contract'),
                    route: '/user/account/contract'
                },
                // {
                //     icon: 'el-icon-share',
                //     name: this.$t('account.inviting_and_awards'),
                //     route: '/user/account/inviting'
                // }
                {
                    icon: 'el-icon-coin',
                    name: this.$t('recharge_manage.title'),
                    route: '/user/account/recharge_manage/USDT'
                },
            ]
        }
    },
    methods: {
        onNav: function (route){
            if (!route) return
            if (this.$route.path == route) return
            this.$nav.replace(route)
        }
    }
}
</script>
<style lang="scss" scoped>
.menu-item{
    border-left: 4px solid transparent;
}
.menu-item.active,
.menu-item:hover{
    border-left: 4px solid $--color-primary;
    color: $--color-primary;
}
</style>